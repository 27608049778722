import './logoslider.scss';

import * as dompack from 'dompack';

// require('slick-carousel');

dompack.onDomReady(() => {
  $('.emb-logoslider').each(function() {
    let $sliderContainer = $(this).find('.emb-logoslider__slider');

    $sliderContainer.slick({
      slidesToShow: 3,
      infinite: true,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });

    $(this).find('.js-prev').click(function(evt) {
      evt.preventDefault();
      $sliderContainer.slick('slickPrev');
    });

    $(this).find('.js-next').click(function(evt) {
      evt.preventDefault();
      $sliderContainer.slick('slickNext');
    });
  });
});
